@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100;200;300;400;500&display=swap');
@font-face {
  font-family: "Overused Grotesk";
  src:
          url("../public/fonts/OverusedGrotesk-VF.woff2") format("woff2 supports variations"),
          url("../public/fonts/OverusedGrotesk-VF.woff2") format("woff2-variations");
  font-weight: 300 900;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 10px;
  list-style: none;
  padding: 0 0 0 0vw;
  margin-top: 0;
}

.grid-mobile-title {
  margin-top: 0.3em;
  margin-bottom: 0.2em;
  margin-left: 3vw;
  display: none;
}

.image-gallery>li {
  flex-basis: 23vw;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.image-gallery::after {
  content: "";
  flex-basis: 22vw;
}

.image-gallery li img {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: transparent;
  display: none;
  /* center overlay text */
  align-items: center;
  justify-content: center;
}

/* hover */
.image-gallery li:hover .overlay {
  display: flex;
  color: #fff;
}

.thumbnail-wrapper {
  overflow: hidden;
}

.image-gallery li:hover img {
  filter: blur(10px);
  transform: scale(1.05);
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

button:hover {
  text-decoration: underline;
  text-decoration-style: dashed;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: underline;
  text-decoration-style: dashed;
}

body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: "Helvetica Neue", "Overused Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
  margin: 0;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  height: 15vh;
  margin-left: 1vw;
}

#isabel {
  font-size: 1.5em;
}

.h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: 500;
}

#menu-button {
  display: none;
  margin-right: 3vw;
  font-size: 1.5em;
  font-weight: 500;
}

nav {
  /*width: 20vw;*/
  position: fixed;
  top: 15vh;
  margin-left: 1vw;
  z-index: 1;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

nav ul table {
  margin: 1vh 0 2vh 0;
}

main {
  width: 77vw;
  margin-left: 40ch;
  margin-top: 15vh;
}

#thumbnail_container {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  column-gap: 0.5vw;
  grid-row-gap: 0.5vw;
  width: 100%;
  z-index: 1;
}

#thumbnail_container a {
  font-style: normal;
}

.thumbnail {
  position: relative;
  text-align: center;
  color: white;
  overflow: hidden;
}

.thumbnail-text {
  color: #ffffff00;
  font-size: 2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 300;
}

.thumbnail:hover .thumbnail-text {
  color: black;
}

.thumbnail-img {
  display: block;
  width: 100%;
  height: 40vh;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;


}

.thumbnail:hover .thumbnail-img {
  opacity: 70%;
  filter: blur(15px);
}

/*puta pos-se o burro*/
#burrice-thumbnail-img {
  background-position: right;

}

/*puta mudou-se  as variantes */
#lang {
  position: fixed;
  bottom: 6vh;
  left: 1vw;
  z-index: 1;
}

.ano {
  color: #767676;
}

.project-link-li {
  margin-left: 0em;
}

.project-link-year {
  color: darkgray;
}

.current-project-link-a {
  text-decoration: underline;
}

/*---------------------------------tablet----------------------------------*/

/*@media only screen and (max-width: 1023px) {
    #thumbnail_container {
        grid-template-columns: 50% 50%;
    }
}*/

/*---------------------------------mobile----------------------------------*/

@media only screen and (max-width: 1024px) {
  .image-gallery {
    padding: 0;
    gap: 0;
  }

  /* .image-gallery li:hover img {
    filter: blur(0);
    transform: scale(1);
  } */

  .main-menu {
    display: none;
  }

  #show-menu-false {
    display: none;
  }

  .thumbnail-wrapper {
    /*margin-bottom: 0.2em;*/
  }

  #menu-button {
    display: unset;
    margin-right: 3vw;
  }

  /* .grid-mobile-title {
    display: unset;
  }

  .overlay-text {
    display: none;
  }

  .overlay {
    display: none;
  } */

  .image-gallery>li {
    flex-basis: unset;
  }

  header {
    display: flex;
    position: relative;
    font-size: 4vmin;
    height: 10vh;
    margin-left: 0;
    width: 100vw;
  }

  nav {
    position: initial !important;
    padding-left: 3vw;
    font-size: 5vmin
  }

  /*  puta mudou-se valores do lang*/
  #lang {
    /*top: unset!important;
    bottom: 3vh;
    left: unset;
    right: 3vw;*/
    display: none;
  }

  #isabel {
    font-size: 7vmin;
    margin-left: 3vw;
  }

  main {
    margin: 0 !important;
    width: 100%;
    font-size: 5vmin;
  }

  nav {
    position: relative;
  }

  #thumbnail_container {
    grid-template-columns: auto;
  }

  #project-container {
    margin: 0 !important;
    max-width: unset !important;
  }

  #project-text {
    margin: 0 3vw 0 3vw;
  }

  #gallery-controls {
    margin: 0 1ch 0 1ch !important;
  }

  #prev {
    margin-left: 3vw;
  }

  #cv-container {
    margin: 0 3vw 0 3vw !important;
    max-width: unset !important;
  }

  #bio-container {
    margin: 0 3vw 0 3vw !important;
    max-width: unset !important;
  }

  .cv-date {
    width: 8ch !important;
  }
}

/*puta mudou-se tamanhos*/
#project-container {
  max-width: 100ch;
}

#project-title {
  margin-bottom: 0;
}

#project-description {
  color: #767676;
  margin-top: 0.2vh;
}

/*puta nao precisa idsso aqui*/
#project-text {
  max-width: 70ch;
}

#images img {
  width: 100%;
}

.mt05 {
  margin-top: 0.5em;
} 

#gallery-controls {
  margin: 0;
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
}

.number-button {
  margin-left: 1ch;
}

#siema-gallery img {
  width: 100%;
}

.cv-project-title {
  font-style: italic;
}

#cv-container,
#bio-container {
  margin-left: 7vw;
  max-width: 85ch;
}

#cv-container a,
#bio-container a,
#project-container a {
  color: blue;
}

.table-title {
  color: #767676;
}

.cv-table {
  border-spacing: 0 1em;
}

.cv-entry {
  margin-bottom: 1em;
}

.cv-date {
  vertical-align: top;
  width: 12ch;
  color: #767676;
}

.cv-heading {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: 500;
}

.back {
  color: #000000 !important;
}